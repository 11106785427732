<template>
  <SoundButton />
  <Footer />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import SoundButton from "./components/SoundButton.vue";
import Footer from "./components/Footer.vue";

@Options({
  components: {
    SoundButton,
    Footer,
  },
})
export default class App extends Vue {}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url("./assets/background.jpg");
  background-size: cover;
  background-position: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}
</style>
