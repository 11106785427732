
import { Options, Vue } from "vue-class-component";
import SoundButton from "./components/SoundButton.vue";
import Footer from "./components/Footer.vue";

@Options({
  components: {
    SoundButton,
    Footer,
  },
})
export default class App extends Vue {}
