<template>
  <footer>
    Gemaakt met ❤️ door
    <br />
    <div class="name">
      <img
        class="profile-image"
        src="../assets/nietthijsbeer.png"
        alt="NietThijsbeer"
      />
      NietThijsbeer
    </div>
  </footer>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class SoundButton extends Vue {
  private SOUND_COUNT = 48;
  play(): void {
    const id = Math.floor(Math.random() * this.SOUND_COUNT) + 1;
    const audio = new Audio(`/assets/sounds/${id}.mp3`);
    audio.addEventListener("loadeddata", audio.play);
  }
}
</script>

<style scoped lang="scss">
footer {
  width: 100%;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 10px;
  text-align: center;
  line-height: 1.6;
  background: linear-gradient(
    rgba(#a0514f, 0),
    rgba(#a0514f, 0.2),
    rgba(#a0514f, 0.6)
  );
}

.name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.profile-image {
  border-radius: 50%;
  margin-right: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
