
import { Options, Vue } from "vue-class-component";

@Options({})
export default class SoundButton extends Vue {
  private SOUND_COUNT = 48;
  play(): void {
    const id = Math.floor(Math.random() * this.SOUND_COUNT) + 1;
    const audio = new Audio(`/assets/sounds/${id}.mp3`);
    audio.addEventListener("loadeddata", audio.play);
  }
}
