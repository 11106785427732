<template>
  <div class="btn-container">
    <button class="sound-btn" @mousedown="play">
      <img class="frog" src="../assets/appelkWeary.png" alt="appelkWeary" />
    </button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class SoundButton extends Vue {
  private SOUND_COUNT = 48;
  play(): void {
    const id = Math.floor(Math.random() * this.SOUND_COUNT) + 1;
    const audio = new Audio(`/assets/sounds/${id}.mp3`);
    audio.addEventListener("loadeddata", audio.play);
  }
}
</script>

<style scoped lang="scss">
.btn-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.sound-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 80vw;
  max-width: 240px;
  max-height: 240px;
  border-radius: 50%;
  background: #ffce67;
  cursor: pointer;
  border: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
  flex-grow: 1;
  transition: margin-top 150ms cubic-bezier(0.34, 1.56, 0.64, 1),
    box-shadow 150ms cubic-bezier(0.34, 1.56, 0.64, 1);

  &:hover {
    margin-top: 2px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  }

  &:active {
    margin-top: 6px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
  }
}

.frog {
  width: 70%;
  height: 70%;
  pointer-events: none;
}
</style>
